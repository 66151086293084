<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import UniversityService from "@/services/UniversityService";

	// Other
	import qs from "qs";

	export default {
		props: {
			countryId: {
				default: null
			},
			isCountryRequired: {
				type: Boolean,
				default: false
			},
            type:{
                type: Number,
                default: null
            },
            isTypeRequired:{
                type: Boolean,
                default: false
            },
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
            public: {
                type: Boolean,
                default: false
            },
            isOther: {
                type: Boolean,
                default: false
            }
		},
		data() {
			return {
				selected: null,
				options: [],
			}
		},
		watch: {
			countryId: function () {
				this.getOptions();
			},
            type: function () {
				this.getOptions();
			},
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			}
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
                this.clearOptions();
				if (this.isCountryRequired && !this.countryId) {
					return;
				}
                if (this.isTypeRequired && !this.type) {
					return;
				}

				let filter = {
                    country_id: this.countryId ? this.countryId:null,
                    type: this.type ? this.type:null,
                }

                const config = {
                    params: {
                        filter: filter,
                        sort: ('name'),
                        limit: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                if(this.public){
                    UniversityService.getAllPublic(config)
                                     .then(response => {
                                         const data = response.data.data;
                                         data.map(item => {
                                             this.options.push({
                                                 value: item.id,
                                                 text: item.name,
                                             });
                                         })
                                         if(this.isOther){
                                             this.options.push({
                                                 value: 'other',
                                                 text: this.$t('other'),
                                             });
                                         }
                                     })
                                     .then(() => {
                                         this.selected = this.setSelected(this.value, this.options)
                                     })
                }
                else {
                    UniversityService.getAll(config)
                                     .then(response => {
                                         const data = response.data.data;
                                         data.map(item => {
                                             this.options.push({
                                                 value: item.id,
                                                 text: item.name,
                                             });
                                         })
                                         if(this.isOther){
                                             this.options.push({
                                                 value: 'other',
                                                 text: this.$t('other'),
                                             });
                                         }
                                     })
                                     .then(() => {
                                         this.selected = this.setSelected(this.value, this.options)
                                     })
                }
			},
			clearOptions() {
				this.options = [];
				this.selected = null;
			}
		}
	}
</script>
