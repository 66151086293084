<template>
	<div>
		<multiselect
			v-model="selected"
			:options="options"
			label="text"
			track-by="value"
			:class="validateError != '' ? 'box-border-color' : ''"
			:placeholder="$t('select')"
			:multiple="multiple"
			:close-on-select="!multiple"
			:clear-on-select="!multiple"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			@input="handleInput($event, multiple, multipleReturnType)"
		>
			<template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
			</template>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError"
		/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	export default {
		props: {
			value: {
				type: String | Array,
				default: null,
			},
			validateError: {
				type: String,
				default: '',
			},
			multiple: {
				type: Boolean,
				default: false,
			},
			multipleReturnType: {
				type: String,
				default: 'withComma',
			}
		},
		created() {
			this.selected = this.setSelected(this.value, this.options, this.multiple, this.multipleReturnType);
		},
		data() {
			return {
				selected: null,
				options: [
					{value: "YL", text: this.$t("program_level_yl")},
					{value: "D", text: this.$t("program_level_d")},
				]
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple, this.multipleReturnType);
			}
		},
		methods: {
			translateNSelected: translateNSelected,
			setSelected: setSelected,
			handleInput: handleInput
		}
	}
</script>
